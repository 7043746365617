.spg-action-bar {
  display: flex;
  box-sizing: content-box;
  padding-right: calcSize(0.5);
  position: relative;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
  white-space: nowrap;

  .sv-action:not(.sv-action--hidden):not(:last-of-type)>.sv-action__content {
    padding-right: 0;
  }
}

.spg-action-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  padding: calcSize(1);
  box-sizing: border-box;
  border: none;
  color: var(--ctr-actionbar-button-text-color, $primary);
  font-weight: 600;
  font-size: calcSize(2);
  line-height: calcSize(3);
  font-family: $font-family;
  border-radius: calcSize(12.5);
  background-color: transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 1;
    outline: none;
    background-color: var(--ctr-actionbar-button-background-color-hovered, $primary-light);

    use {
      fill: var(--ctr-actionbar-button-icon-color-hovered, $primary);
    }
  }
}

.spg-action-button__icon {
  display: block;

  use {
    fill: var(--ctr-actionbar-button-icon-color, $foreground-light);
  }
}

.spg-action-button--danger {
  color: var(--ctr-library-action-button-text-color-negative, $red);

  &:hover,
  &:focus {
    background-color: var(--ctr-library-action-button-background-color-negative-hovered, $red-light);

    use {
      fill: var(--ctr-library-action-button-icon-color-negative, $red);
    }
  }
}

button.spg-action-button--large {
  padding: calcSize(0.5) calcSize(2);
}

.spg-action-button:disabled {
  opacity: var(--ctr-library-action-button-opacity-disabled, 0.25);
  pointer-events: none;
  cursor: default;
}

.spg-action-button--muted {
  opacity: 0.5;
}

.spg-action-button:active {
  opacity: 0.5;
}

.spg-action-button--text {
  padding: calcSize(0.5) calcSize(2);
}