.desktopHeader {
  display: flex;
  padding: 24px 28px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 300;
}

.desktopNav {
  ul {
    padding: 0 32px;
    list-style: none;
  }
}

.mobileNav {
  display: none;
}

.leftDesktopNav,
.rightDesktopNav {
  display: flex;
  align-items: center;
}

.rightDesktopNav {
  gap: 8px;
}

.navItem {
  display: inline-block;
  margin-left: 8px;
  a {
    padding: 12px;
    color: inherit;
    text-decoration: none;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
  }
}

.activeNavItem {
  background-color: #27272a;
}

@media (max-width: 1023px) {
  .desktopHeader {
    padding: 24px 1rem;
  }
  .desktopNav {
    ul {
      display: none;
    }
  }
  .mobileNav {
    display: block;
    width: 250px;
    padding: 10px;
    ul {
      padding: 5px;
      list-style: none;
      display: flex;
      flex-direction: column;
    }
  }

  .navItem {
    width: 100%;
    display: block;
    margin-left: 0px;
    a {
      display: flex;
      width: 100%;
    }
  }

  .hambtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
