.textarea {
  background: #1f1f22;
  outline: none;
  border: none;
  border-radius: 12px;
  display: flex;
  padding: 18px 16px;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.textareaContainer {
  position: relative;
}
