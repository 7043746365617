.subscriptionCard {
  position: relative;
  color: gray;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  padding: 24px 12px;
  gap: 15px;
  .title {
    white-space: nowrap;
    font-size: 18px;
    letter-spacing: 0.1em;
    font-weight: bold;
    text-transform: capitalize;
  }
  .messageSection {
    border-radius: 8px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    padding: 12px;
    color: rgb(94, 92, 92);
    width: 100%;
    min-height: 100px;

    @media (max-width: 1920px) {
      min-height: 140px;
    }

    @media (max-width: 760px) {
      min-height: 120px;
    }
  }
  .priceSection {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    text-align: center;
    .dollar {
      font-weight: 700;
      color: #a855f7;
      font-size: 48px;
      line-height: 1;
      position: absolute;
    }
    .price,
    .priceCustom {
      height: 170px;
      line-height: 170px;
      font-size: 113px;
      color: black;
    }
    .priceCustom {
      font-size: 80px;
    }
  }

  .starSection {
    width: 100%;
    padding: 12px;
    .row {
      display: flex;
      align-items: flex-start;
      gap: 8px;
    }
    .rowText {
      font-size: 13px;
      line-height: 1rem /* 16px */;
      text-align: left;
      margin-bottom: 0.25rem;
    }
  }
  .arrowBtn {
    margin-right: auto;
    margin-left: auto;
    margin-top: auto;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: white;
    background-color: #a855f7;
  }
  .saleBtn {
    margin-top: auto;
  }
}
