.footer {
  padding: 24px 28px;
  margin-bottom: 24px;
  gap: 40px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--zinc-800, #27272a);
  font-size: 15px;
  // background: var(--zinc-900, #18181b);
  a {
    color: #e4e4e7;
    text-decoration: none;
    font-size: 12px;
  }
}

.footerNoMargin {
  margin: 0;
}

.footerSubscribe {
  font-size: 12px;
  p {
    span {
      color: "#A855F7";
    }
  }
}

@media (max-width: 760px) {
  .footer {
    padding: 28px 1rem;
    margin: 6px;
  }
}
