@import "../variables.scss";

// .svc-question__content {
//   .sd-table {
//     td:empty,
//     th:empty {
//       width: initial;
//     }
//   }
// }
.svc-matrix-cell {
  position: relative;
}

.svc-question__adorner {
  .sd-table .sd-table__cell--column-title {
    padding: 0  calcSize(1);
    .svc-matrix-cell {
      padding: calcSize(1.5) calcSize(1);
    }
  }
}

.svc-matrix-cell--selected {
  position: absolute;
  border: calcSize(0.25) solid $secondary;
  border-radius: calcSize(0.5);
  top: calcSize(0);
  left: calcSize(0);
  right: calcSize(0);
  bottom: calcSize(0);
  display: none;
  &.svc-visible {
    display: block;
  }
}

.svc-hovered > .svc-matrix-cell--selected:not(.svc-visible) {
  border: calcSize(0.25) solid $secondary-light;
  border-radius: calcSize(0.5);
  display: block;
}

.svc-matrix-cell__question-controls {
  display: none;
  position: absolute;
  top: calc(50% - 3 * #{$base-unit});
  left: calc(50% - 3 * #{$base-unit});
  z-index: 2;
}

.svc-matrix-cell__question-controls-button {
  display: block;
  width: calcSize(6);
  height: calcSize(6);
  background: $background;
  border: 1px solid $border;
  box-sizing: border-box;
  border-radius: 50%;
  padding: calcSize(1.5);
  cursor: pointer;

  use {
    fill: $foreground-light;
  }
}

.svc-matrix-cell:hover .svc-matrix-cell__question-controls {
  display: block;
}

.svc-question__content--in-popup {
  min-width: calcSize(70);
  padding: calcSize(5);
}

.svc-matrix-cell__popup {
  .sv-popup__scrolling-content {
    margin-top: calcSize(1);
    padding-top: 0;
  }

  .svc-question__content {
    @include disableUserSelect;
  }
}

.svc-question__content {
  .sd-table__cell--detail-panel {
    padding-left: calcSize(6);
  }
}