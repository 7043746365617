.main {
  button {
    border-radius: 12px;
    padding: 12px 16px;
    background-color: white;
    box-shadow: none;
    svg {
      display: none;
    }
    div {
      color: black;
      font-size: 14px;
    }
  }
}
