.dropdown {
  width: 180px !important;
  background-color: #ffffff;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 0px !important;
  color: #71717a !important;
  border-radius: 12px !important;
  padding: 8px 16px !important;
  font-size: 14px !important;
}

.numOfSurveys {
  width: 260px;
}

.slider {
  width: 50% !important;
}

.dropdowns,
.dropdowns2 {
  display: flex;
  width: 100%;
  gap: 12px;
}
.dropdowns1 {
  display: flex;
  gap: 12px;
}
.dropdowns2 {
  justify-content: space-between;
}

@media (max-width: 760px) {
  .numOfSurveys {
    width: 100%;
    margin-bottom: 1rem;
  }
  .slider {
    width: 45% !important;
  }
  .dropdown {
    width: 100%;
    padding: 10px;
    box-sizing: border-box !important;
  }
  .dropdowns1 {
    display: block;
  }
  .dropdowns2,
  .dropdowns1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .dropdowns {
    display: block !important;
    width: 100%;
  }
}
