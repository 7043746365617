.wrapper {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  scale: 1.8;
}
