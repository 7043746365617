.spg-matrixdynamic__drag-element {
  padding: calcSize(2);

  &:hover {
    background-color: $background;
  }

  &:after {
    content: " ";
    display: block;
    height: calcSize(0.5);
  }
}

.spg-drag-element__svg {
  width: calcSize(3);
  height: calcSize(3);
  padding: calcSize(1);
  display: block;
  opacity: 0.5;

  use {
    fill: var(--ctr-data-table-row-drag-area-icon-color, $foreground-light);
  }
}

.spg-matrixdynamic__drag-drop-ghost-position-top,
.spg-matrixdynamic__drag-drop-ghost-position-bottom {
  position: relative;
}

.spg-matrixdynamic__drag-drop-ghost-position-top::after,
.spg-matrixdynamic__drag-drop-ghost-position-bottom::after {
  content: "";
  width: 100%;
  height: calcSize(0.5);
  background-color: $secondary;
  position: absolute;
  left: 0;
}

.spg-matrixdynamic__drag-drop-ghost-position-top::after {
  top: 0;
}

.spg-matrixdynamic__drag-drop-ghost-position-bottom::after {
  bottom: 0;
}

.spg-matrixdynamic__placeholder {
  padding: calcSize(6) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--ctr-property-grid-background-color, $background);
  border: 1px solid var(--ctr-survey-placeholder-border-color, $border);
  box-sizing: border-box;

  .spg-matrixdynamic__add-btn {
    display: initial;
  }
}

.spg-matrixdynamic__placeholder-text {
  font-size: calcSize(2);
  line-height: calcSize(3);
  color: var(--ctr-survey-placeholder-text-color, $foreground-light);
}

.spg-matrixdynamic__add-btn {
  display: none;
  appearance: none;
  border: none;
  margin-top: calcSize(0.5);
  background: transparent;
  color: var(--ctr-survey-action-button-text-color-positive, $primary);
  font-family: $font-family;
  font-size: calcSize(2);
  font-weight: 600;
  line-height: calcSize(3);
}

.spg-smiley-icon {
  width: calcSize(3);
  height: calcSize(3);
  margin: calcSize(1);
  border-radius: 100px;
  border: 1px solid var(--ctr-actionbar-button-icon-color, $foreground);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calcSize(4);

  svg {
    width: calcSize(1.5);
    height: calcSize(1.5);
    fill: var(--ctr-actionbar-button-icon-color, $foreground);
  }
}