.paymentForm {
  max-width: 720px;
  background-color: transparent;
}

.paymentElement {
  background-color: transparent;
  margin-bottom: 16px;
}

.input {
  background-color: transparent;
  outline: none;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  font-size: 0.875rem;
  font-family: "Inter", serif;
  margin-top: auto;
  margin-bottom: auto;
}
