.modal {
  max-width: 504px;
  &Header {
    margin-bottom: 32px;
  }

  &HeaderNotification {
    margin-bottom: 36px;
  }
  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 140% */
    margin-bottom: 16px;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom: 32px;
  }
}
