.input {
  background: #1f1f22;
  outline: none;
  border: none;
  border-radius: 12px;
  display: flex;
  padding: 12px 16px;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.inputContainer {
  position: relative;
}

.inputPrefixIcon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-40%);
}

.inputSuffixIcon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-40%);
}
